.agents-container {
    width: 80%;
    margin: auto;
    text-align: center;
}

.agents-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.agents-table th, .agents-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.agents-table th {
    background-color: #f2f2f2;
    color: black;
}

.agents-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.agents-table tr:hover {
    background-color: #ddd;
}

button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

.pagination button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
}

.pagination button:hover {
    background-color: #45a049;
}