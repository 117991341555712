.game-container {
    font-family: Arial, sans-serif;
    padding: 20px;
}

.instructions {
    margin-top: 20px;
}

.instructions h4 {
    margin-top: 10px;
}

button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}